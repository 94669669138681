<template>
  <v-footer
    id="footer"
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
      color="#E9E5DD"
      light
      height="105px"
    >
      <v-card-title>
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="#999999"
          text
          rounded
          class="my-2"
          @click="toRoute(link.routeName)"
        >
          {{ link.name }}
        </v-btn>

        <v-spacer></v-spacer>

        <span class="text">
          Connect with CertainFee
        </span>
        <v-btn
          class="mx-4"
          color="#999999"
          icon
          href="https://www.facebook.com/certainfee/?ref=pages_you_manage"
          target="_blank"
        >
          <v-icon size="24px">
            mdi-facebook
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-4"
          color="#999999"
          icon
          href="https://www.instagram.com/crites_business_solutions/"
          target="_blank"
        >
          <v-icon size="24px">
            mdi-instagram
          </v-icon>
        </v-btn>
      </v-card-title>

    </v-card>
  </v-footer>
</template>

<script>
import router from '../router'

export default {
  data: () => ({
    links: [
      {
        name: 'Home',
        routeName: 'Home',
      },
      {
        name: 'About Us',
        routeName: 'About',
      },
      {
        name: 'Sign In',
        routeName: 'SignIn',
      },
      {
        name: 'Contact',
        routeName: 'ContactUs',
      },
    ],
  }),
  methods: {
    toRoute(routeName) {
      if(this.$route.name !== routeName) {
        router.push({ name: routeName })
      }
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 10pt;
  font-family: "NunitoSans-Bold";
  color: #999999;
}
</style>